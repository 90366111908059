import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import Packages from "./Packages";
import Documents from "./Documents";
import Labels from "./Labels";
import {
  updateInvoice
} from "../reducers/invoicesReducer";
import ShowTracking from "./Tracking";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: "auto"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  menu: {
    width: 200
  },
  buttonField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    display: "flex"
  }
}));

const vatOptions = [
  {
    value: false,
    label: 'ללא מע"מ'
  },
  {
    value: true,
    label: 'מע"מ'
  }
];

const statusOptions = [
  {
    value: false,
    label: "לא נמסר"
  },
  {
    value: true,
    label: "Delivered"
  }
];

export default function CarrierInvoice(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const accountsState = useSelector(state => state.accounts);

  statusOptions[0].label = props.status;
  const[ declaration_no, setDeclarationNo] = useState(props.declaration_no);
  const[ tracking_no, setTrackingNo] = useState(props.tracking_no);
  const[ignore_duplicates, setIgnoreDuplicates] = useState(props.ignore_duplicates)
  const [no_import_document, setNoImportDocument] = useState(props.no_declaration)
  const [weight_is_correct, setWeightIsCorrect] = useState(props.weight_is_correct)
  const [ignore_invoice, setIgnoreInvoice] = useState(props.status !== null)
  const [xc_charges, setXCcharges] = useState(props)
  const [xc_charges_changed, setXCChargesChanged] = useState(false)

  const [account, setAccount] = useState(props.account);

  const tracking = (e, carrier, tracking) => {
    e.preventDefault();
  };

  const getXCFields = record => {
    let calcuatatedTotal = Number(record.round || 0);
    return (
      <React.Fragment>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(index => {
          if (Number(record[`xc${index}_charge`]) > 0) {
            calcuatatedTotal +=
              Number(record[`xc${index}_charge`]) *
              (record[`xc${index}_vat`] != null && record[`xc${index}_vat`] == 1
                ? 1.17
                : 1);
              return (
              <React.Fragment key={index}>
                <TextField
                  label={`xc${index}_name`}
                  className={classes.textField}
                  value={props[`xc${index}_name`]}
                  margin="dense"
                  InputProps={{
                    readOnly: true
                  }}
                  variant="filled"
                />
                <TextField
                  label={`xc${index}_charg`}
                  className={classes.textField}
                  value={xc_charges[`xc${index}_charge`]}
                  margin="dense"
                  variant="filled"
                  onChange={ev=>handleChargeChange(`xc${index}_charge`, ev.target.value)}
                />
                <TextField
                  select
                  label={`xc${index}_vat`}
                  className={classes.textField}
                  value={record[`xc${index}_vat`] == 1}
                  margin="dense"
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  variant="filled"
                >
                  {vatOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </React.Fragment>
            );
          }
        })}
        <TextField
          label="Calculated"
          className={classes.textField}
          value={calcuatatedTotal.toFixed(2)}
          margin="dense"
          InputProps={{
            readOnly: true
          }}
          variant="filled"
        />
      </React.Fragment>
    );
  };

  const handleAccountChange = event => {
    setAccount(event.target.value);
    dispatch(updateInvoice(props.id, "set_account", {type: props.carrier_name, account: event.target.value}))
  };
  const updateInvoiceChanges = ()=>{
    if( props.declaration_no !== declaration_no){
      dispatch(updateInvoice(props.id, "set_declaration_no", {type: props.carrier_name, declaration_no}))
    }
    if( props.tracking_no !== tracking_no){
      dispatch(updateInvoice(props.id, "set_tracking_no", {type: props.carrier_name, tracking_no}))
    }
    if( props.ignore_duplicates != ignore_duplicates){
      dispatch(updateInvoice(props.id, "set_ignore_duplicates", {type: props.carrier_name, ignore_duplicates}))
    }
    if( props.no_declaration != no_import_document){
      dispatch(updateInvoice(props.id, "set_no_import_document", {type: props.carrier_name, no_import_document}))
    }
    if( props.weight_is_correct != weight_is_correct){
      dispatch(updateInvoice(props.id, "set_weight_is_correct", {type: props.carrier_name, weight_is_correct}))
    }
    if( ignore_invoice === true)
      dispatch(updateInvoice(props.id, "set_ignore_invoice", {type: props.carrier_name, ignore_invoice}))

    if( xc_charges_changed)
    {
      dispatch(updateInvoice(props.id, "set_xc_charges", {type: props.carrier_name, xc_charges}))
    }
}
  const handleDeclarationChange = declaration_no =>{
    setDeclarationNo(declaration_no)
  }
  const updateDuplicates = checked=>{
    setIgnoreDuplicates(checked)
  }
  const handleTrackingNoChange = tracking_no =>{
    setTrackingNo(tracking_no)
  }
  const handleChargeChange = (field_name, charge)=>{
    setXCChargesChanged(true)
    setXCcharges(charges=>{
      let older = {...charges}
      older[field_name] = charge
      return older  
    })
  }
  
  const showReferenceOrAccount = record => {
    if (!record.reference_no.trim().length) {
      return (
        <TextField
          select
          label="Account"
          className={classes.textField}
          value={account}
          onChange={handleAccountChange}
          margin="dense"
          variant="filled"
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
        >
          {accountsState.list.map(option => (
            <MenuItem key={option.id} value={option.id}>
              {option.name2}
            </MenuItem>
          ))}
        </TextField>
      );
    } else
      return (
        <TextField
          label="Reference"
          className={classes.textField}
          value={record.reference_no}
          margin="dense"
          InputProps={{
            readOnly: true
          }}
          variant="filled"
        />
      );
  };

  return (
    <Container
      style={{
        fontSize: 10,
        textAlign: "left",
        color: "white"
      }}
    >
      {/* <form noValidate autoComplete="off"> */}
      <TextField
        label="Invoice"
        className={classes.textField}
        value={props.invoice_no}
        margin="dense"
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <TextField
        label="Date"
        className={classes.textField}
        value={props.invoice_date}
        margin="dense"
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <ShowTracking
        params={{ tracking_code: props.tracking_no, carrier: props.name, callback: handleTrackingNoChange }}
      />
      {showReferenceOrAccount(props)}
      { props.carrier_name.includes("TAX") && 
      <TextField
        label="Declaration No."
        className={classes.textField}
        value={declaration_no}
        margin="dense"
        onChange={ev=>handleDeclarationChange(ev.target.value)}
        variant="filled"
      />}
      <TextField
        label="Weight"
        className={classes.textField}
        value={props.weight}
        margin="dense"
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <TextField
        label="Total"
        className={classes.textField}
        value={props.total}
        margin="dense"
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <TextField
        label="Round"
        className={classes.textField}
        value={props.round}
        margin="dense"
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      {getXCFields(props)}
      <TextField
        label="message"
        className={classes.textField}
        value={props.message}
        margin="dense"
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <TextField
        label="Time"
        className={classes.textField}
        value={props.eventTime}
        margin="dense"
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <TextField
        label="Diff"
        className={classes.textField}
        value={props.diff}
        margin="dense"
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <ShowTracking
        params={{
          tracking_code: props.tracking_code,
          carrier: props.name
        }}
      />
      <TextField
        label="Location"
        className={classes.textField}
        value={props.last_location}
        margin="dense"
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <TextField
        label="Target"
        className={classes.textField}
        value={props.target_country_code}
        margin="dense"
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <Packages {...props} />
      <TextField
        label="Weight"
        className={classes.textField}
        value={props.calculated_weight}
        margin="dense"
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <TextField
        label="Carrier"
        className={classes.textField}
        value={props.name}
        margin="dense"
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <TextField
        label="Service"
        className={classes.textField}
        value={props.service_name}
        margin="dense"
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <TextField
        label="Company"
        className={classes.textField}
        value={props.company_name}
        margin="dense"
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <Documents {...props} />
      <TextField
        label="Pickup date"
        className={classes.textField}
        value={props.pickup_date}
        margin="dense"
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
      <Labels {...props} />
      <TextField
        label="error"
        multiline 
        className={classes.textField}
        value={props.last_error}
        margin="dense"
        InputProps={{
          readOnly: true
        }}
        variant="filled"
      />
    <FormControl component="fieldset">
      <FormGroup>
        <FormControlLabel 
          control={<Checkbox 
            checked={ignore_duplicates} 
            onChange={ev=>updateDuplicates(ev.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Duplicates"
          style={{color: 'black'}}
          labelPlacement="end"
        />
        <FormControlLabel 
          control={<Checkbox 
            checked={no_import_document} 
            onChange={ev=>setNoImportDocument(ev.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="No Dec."
          style={{color: 'black'}}
          labelPlacement="end"
        />
        <FormControlLabel 
          control={<Checkbox 
            checked={weight_is_correct} 
            onChange={ev=>setWeightIsCorrect(ev.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Weight correct"
          style={{color: 'black'}}
          labelPlacement="end"
        />
        <FormControlLabel 
          control={<Checkbox 
            checked={ignore_invoice} 
            onChange={ev=>setIgnoreInvoice(ev.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Ignore"
          style={{color: 'black'}}
          labelPlacement="end"
        />
      </FormGroup>
      </FormControl>
      <Button
        className={classes.buttonField}
        variant="contained"
        color="primary"
        onClick={updateInvoiceChanges}
      >
        Update
      </Button>
    </Container>
  );
}
