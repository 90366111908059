import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { DropzoneDialog, DropzoneArea } from "material-ui-dropzone";
import API from "../api/accountsapi";
import { saveDocument } from "../reducers/shipmentsReducer";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: "2px 4px",
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "top"
  },
  input: {
    marginLeft: theme.spacing(1)
    // flex: 1,
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

export default function EditDocuments(shipment_id, setEditDocuments) {
  const dispatch = useDispatch();

  const [documents, setDocuments] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openFileUpload, setOpenFileUpload] = useState(false);
  const [fileDescription, setFileDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState({file: [], description: ""});

  const fetchDocuments = async () => {
    const result = await API.getDocumentsForShipment(shipment_id.shipment_id);
    setDocuments(result);
    setOpen(true);
  };

  useEffect(() => {
    if (documents.length === 0) fetchDocuments();
  }, []);

  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
    dispatch(saveDocument(shipment_id.shipment_id, fileDescription, uploadedFiles.file));
    shipment_id.setEditDocuments(false);
  };

  const handleFileUpload = files => {
    console.log(fileDescription, files);
    setUploadedFiles({
      file: files[0],
      description: fileDescription
    })
    setOpenFileUpload(false);
  };

  const changeDescription = fileDescription => {
    setFileDescription(fileDescription);
  };

  return (
    <Fragment>
      <Dialog
        className={classes.root}
        open={open}
        fullWidth
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText>
          {documents.map((document, index) => {
            return (
              <div className={classes.root} key={index}>
                <Grid container justify="center" direction="row" spacing={1}>
                  <Grid item>
                    <Paper component="form" className={classes.root}>
                      <TextField
                        label="Description"
                        value={document.description}
                        margin="normal"
                        InputProps={{
                          readOnly: true
                        }}
                        variant="filled"
                      />
                      <a href={document.url} download>
                        <IconButton
                          type="submit"
                          className={classes.iconButton}
                          aria-label="view"
                          onClick={e => {
                            e.preventDefault();
                            window.open(document.url);
                          }}
                        >
                          <CloudDownloadIcon />
                        </IconButton>
                      </a>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            );
          })}
          <div className={classes.root}>
            <Grid container justify="center" direction="row" spacing={1}>
              <Grid item>
                <Paper component="form" className={classes.root}>
                  <TextField
                    label="Description"
                    value={fileDescription}
                    onChange={e => changeDescription(e.target.value)}
                    margin="normal"
                    variant="filled"
                  />
                  <IconButton
                    type="submit"
                    className={classes.iconButton}
                    aria-label="upload"
                    onClick={e => {
                      e.preventDefault();
                      setOpenFileUpload(true);
                    }}
                  >
                    <CloudUploadIcon />
                  </IconButton>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <DropzoneDialog
        open={openFileUpload}
        filesLimit={1}
        fullWidth
        onSave={handleFileUpload}
        onClose={() => setOpenFileUpload(false)}
      >
        <DropzoneArea submitButtonText={"text"} />
      </DropzoneDialog>
    </Fragment>
  );
}
