import axios from "axios";
import API from "../api/accountsapi";
import { bigIntLiteral } from "../../../../../AppData/Local/Microsoft/TypeScript/3.6/node_modules/@babel/types/lib";

const defaultUids = {
  list: [],
  uids: [],
  isLoading: false,
  isError: false,
  errors: ""
};

export const getAccounts = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(removeAccountsList());
      const accounts = await API.getAccounts();
      dispatch(setAccounts(accounts));
    } catch (err) {
      dispatch(setAccountsError(err.message || err.error.message));
    }
  };
};
export const getUids = (account) => {
  return async (dispatch, getState) => {
    try {
      dispatch(removeUIDSList());
      const uids = await API.getUIDsForAccount(account);
      dispatch(setUids(uids));
    } catch (err) {
      dispatch(setAccountsError(err.message || err.error.message));
    }
  };
};
export const removeAccountsList = (reset=false) => {
  return {
    type: "UIDS_REMOVE_ACCOUNTS_LIST",
    reset
  };
};

export const setAccounts = accounts => {
  return {
    type: "UIDS_SET_ACCOUNTS_LIST",
    accounts
  };
};
export const setUids = uids=>{
  return {
    type: "UIDS_SET_UIDS",
    uids
  }
}
export const setAccountsError = data => {
  return {
    type: "UIDS_ACCOUNTS_ERROR",
    data
  };
};
export const clearAccountsError = () => {
  return {
    type: "UIDS_CLEAR_ACCOUNTS_ERROR"
  };
};
export const removeUIDSList = ()=>{
  return {
    type: "UIDS_REMOVE_UIDS"
  }
}
export default (state = defaultUids, action) => {
  switch (action.type) {
    case "UIDS_REMOVE_ACCOUNTS_LIST":
      return {
        ...defaultUids,
        isLoading: !action.reset
      }
    case "UIDS_SET_ACCOUNTS_LIST": {
      return {
        ...state,
        list: action.accounts,
        uids: [],
        isLoading: false,
        isError: false,
        errors: ""
      };
    }
    case "UIDS_SET_UIDS":{
      return {
        ...state,
        uids: action.uids,
        isLoading: false,
        isError: false,
        errors: ""
      }
    }
    case "UIDS_ACCOUNTS_ERROR": {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errors: action.data
      };
    }
    case "UIDS_CLEAR_ACCOUNTS_ERROR": {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errors: ""
      };
    }
    case "UIDS_REMOVE_UIDS":{
      return {
        ...state,
        uids: [],
        isLoading: true,
        isError: false,
        errors: ""
      }
    }
    default:
      return state;
  }
};
