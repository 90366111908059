import React, {  useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import MenuItem from "@material-ui/core/MenuItem";

import Packages from "./Packages"
import Options from "./Options"
import Documents from "./Documents"
import ShipmentInvoices from "./ShipmentInvoices"
import Labels from "./Labels"
import MoveAccount from "./MoveAccount"
import Schedule from "./EditSchedule"
import { updateShipment} from "../reducers/activeShipmentsReducer"

import ShowTracking from "./Tracking"
import EditAddress from "./EditTargetDetails";
import Customs from "./Customs";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: "auto"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  //style for font size
  resize:{
    fontSize:"1rem"
  },
  resize_small:{
    fontSize:"0.8rem"
  },
  menu: {
    width: 200
  },
  buttonField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    display: "flex"
  }
}));

const statusOptions = [
  {
    value: "InTransit",
    label: "נאסף"
  },
  {
    value: "waiting for pickup",
    label: "מחכה לאיסוף"
  },
  {
    value: "Delivered",
    label: "פוזר"
  }
];

export default function Shipment(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  statusOptions[0].label = props.status;
  const [status, setStatus] = useState(props.status);
  const [showMoveAccount, setShowMoveAccount] = useState(false);
  const [editOptions, setEditOptions] = useState(false);
  const [editTargetDetails, setEditTargetDetails] = useState(false);
  const [editSourceDetails, setEditSourceDetails] = useState(false);  
  const [editCustomsDetails, setEditCustomsDetails] = useState(false);  

  const onGenerateLabel = (e, shipment_id)=>{
    e.preventDefault();
    dispatch(updateShipment(shipment_id, "generate_label", {}));
  }

  const onGenerateInvoice = (e, shipment_id)=>{
    e.preventDefault();
    dispatch(updateShipment(shipment_id, "generate_commercial_invoice", {}));
  }

  const onGenerateDocument = (e, shipment_id)=>{
    e.preventDefault();
    dispatch(updateShipment(shipment_id, "generate_one_document", {}));
  }

  const handleStatusChange = event => {
    setStatus(event.target.value);
    dispatch(updateShipment(props.id, "set_status", {status: event.target.value}))
  };
  
  let source_details = `${props.source_company}`
  let target_details = `${props.target_company}`
  let customs_details = `${props.description},${props.insurance_fee},${props.terms_of_sale},${props.value}`
  

  return (
    <React.Fragment>
    <Grid container spacing={3}
    >
      <Grid item xs={12} sm={3} md={2}>
        <TextField
          label="Number"
          className={classes.textField}
          value={props.number}
          margin="normal"
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.resize,
            }
            }
          }
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <TextField
          label="Customer"
          className={classes.textField}
          value={props.customer}
          margin="normal"
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.resize,
            }
          }}
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <TextField
          select
          label="status"
          className={classes.textField}
          value={status}
          onChange={handleStatusChange}
          margin="normal"
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
        variant="standard"
        >
        {statusOptions.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <TextField
          label="message"
          className={classes.textField}
          value={props.message}
          margin="normal"
          multiline 
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.resize,
            }
          }}
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <TextField
          label="Time"
          className={classes.textField}
          value={props.eventTime}
          margin="normal"
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.resize,
            }
          }}
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <TextField
          label="Diff"
          className={classes.textField}
          value={props.diff}
          margin="normal"
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.resize,
            }
          }}
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <ShowTracking params={{tracking_code: props.tracking_code, carrier: props.name}}/>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <TextField
          label="Location"
          className={classes.textField}
          value={props.last_location}
          margin="normal"
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.resize,
            }
          }}
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <TextField
          label="Target"
          className={classes.textField}
          value={props.target_country_code}
          margin="normal"
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.resize,
            }
          }}
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <Packages {...props}/>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <TextField
          label="Weight"
          className={classes.textField}
          value={props.calculated_weight}
          margin="normal"
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.resize,
            }
          }}
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <TextField
          label="Carrier"
          className={classes.textField}
          value={props.name}
          margin="normal"
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.resize,
            }
          }}
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <TextField
          label="Service"
          className={classes.textField}
          value={props.service_name}
          margin="normal"
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.resize,
            }
          }}
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <TextField
          label="Target"
          multiline 
          className={classes.textField}
          value={target_details}
          margin="normal"
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.resize_small,
            }
          }}
          variant="standard"
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          onClick={e => {
            e.preventDefault();
            setEditTargetDetails(true)
          }}
        >
          <EditIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <TextField
          label="Source"
          multiline 
          className={classes.textField}
          value={source_details}
          margin="normal"
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.resize_small,
            }
          }}
          variant="standard"
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          onClick={e => {
            e.preventDefault();
            setEditSourceDetails(true)
          }}
        >
          <EditIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <TextField
          label="Customs"
          multiline 
          className={classes.textField}
          value={customs_details}
          margin="normal"
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.resize_small,
            }
          }}
          variant="standard"
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          onClick={e => {
            e.preventDefault();
            setEditCustomsDetails(true)
          }}
        >
          <EditIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <Documents {...props}/>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <ShipmentInvoices {...props}/>
      </Grid>
      {editTargetDetails && <EditAddress id={props.target_address_id} setEditAddress={setEditTargetDetails}/>}
      {editSourceDetails && <EditAddress id={props.source_address_id} setEditAddress={setEditSourceDetails}/>}
      {editCustomsDetails && <Customs id={props.id} setCustoms={setEditCustomsDetails}/>}
      {editOptions && <Options {...props}/>}
      <Grid item xs={12} sm={3} md={2}>
        <Labels {...props}/>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <Schedule {...props}/>

        {/* <TextField
          label="Pickup date"
          className={classes.textField}
          value={props.pickup_date}
          margin="normal"
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.resize,
            }
          }}
          variant="standard"
        />         */}
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <Grid>
          <Button variant="contained" color="primary" onClick={(e)=>setShowMoveAccount(true)}>
            Move Account
          </Button>
          <Button variant="contained" color="primary" type="submit"  onClick={(e)=>onGenerateLabel(e, props.id)}>
            Create Label
          </Button>
          <Button variant="contained" color="primary" type="submit"  onClick={(e)=>onGenerateInvoice(e, props.id)}>
            Generate Invoice
          </Button>
          <Button variant="contained" color="primary" type="submit"  onClick={(e)=>onGenerateDocument(e, props.id)}>
            Generate Document
          </Button>
          <Button variant="contained" color="primary" type="submit"  onClick={(e)=>setEditOptions(!editOptions)}>
            Options
          </Button>
        </Grid>
      </Grid>
    </Grid>
    {showMoveAccount && <MoveAccount shipment_id={props.id} cancel={()=>setShowMoveAccount(false)}/>}
    </React.Fragment>
  );
}
