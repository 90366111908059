import React, { Fragment, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditDocuments from "./EditDocuments";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "inline-flex",
    alignItems: "center",
    width: 200,
    verticalAlign: "top"
  },
  input: {
    marginLeft: theme.spacing(1)
    // flex: 1,
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  resize:{
    fontSize:"1rem"
  },
}));

export default function Documents(rowData) {
  const classes = useStyles();
  const [editDocuments, setEditDocuments] = useState(false);

  return (
    <Fragment>
      <Paper component="form" className={classes.root}>
        <TextField
          label="Documents"
          className={classes.input}
          value={rowData.documents}
          margin="dense"
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.resize,
            }
          }}
          variant="filled"
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          onClick={e => {
            e.preventDefault();
            setEditDocuments(true);
            console.log("setEditPackages")
          }}
        >
          <VisibilityIcon />
        </IconButton>
      </Paper>
      {editDocuments && <EditDocuments shipment_id={rowData.id} setEditDocuments={setEditDocuments} />}
    </Fragment>
  );
}
