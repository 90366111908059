import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getUids, getAccounts, removeAccountsList } from "../reducers/uidsRedcuer"
import { updateShipment} from "../reducers/activeShipmentsReducer"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: "2px 4px",
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "top"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  input: {
    marginLeft: theme.spacing(1)
    // flex: 1,
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

export default function MoveAccount(params) {
  const accountsState = useSelector(state => state.uids);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [account, setAccount] = useState(0);
  const [uid, setUid] = useState(0);

  useEffect(() => {
    const fetchAccounts = async () => {
      dispatch(getAccounts());
    };
    if( accountsState.list.length === 0 && !accountsState.isLoading)
      fetchAccounts();
  }, []);

  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
    params.setEditLabels(false);
  };
  const handleAccountChange = event => {
    setAccount(event.target.value);
    dispatch(getUids(event.target.value))
  };

  const handleUidChange = event => {
    setUid(event.target.value);
  };

  const OnCancel = event =>{
    dispatch(removeAccountsList(true))
    if( params.cancel)
      params.cancel()
  }
  const OnSave = event =>{
    dispatch(updateShipment(params.shipment_id, "move_account", {account_id: account, uid}));
    dispatch(removeAccountsList(true))
    if( params.cancel)
      params.cancel()
  }

  return (
    <React.Fragment>
    <Dialog
      open  
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Move shipment to another acocunt"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        <TextField
          select
          label="Account"
          fullWidth 
          className={classes.textField}
          value={account}
          onChange={handleAccountChange}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
        >
          {accountsState.list.map(option => (
            <MenuItem key={option.id} value={option.id}>
              {option.name2}
            </MenuItem>
          ))}
       </TextField>
        <TextField
          select
          fullWidth 
          label="User"
          className={classes.textField}
          value={uid}
          onChange={handleUidChange}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
        >
          {accountsState.uids.map(option => (
            <MenuItem key={option.id} value={option.id}>
              {option.email}
            </MenuItem>
          ))}
        </TextField>
        {accountsState.isLoading && <CircularProgress/>}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>OnCancel()} color="primary">
          Cancel
        </Button>
        <Button onClick={()=>OnSave()} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
    </React.Fragment>
  );
}
